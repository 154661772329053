import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ProtectedRoute from './helpers/ProtectedRoute';
import NavBar from './components/NavBar/NavBar';
import Login from './pages/Login/Login';
import Reset from './pages/Login/Reset';
import DataEntry from './pages/DataEntry/Index';
import DataView from './pages/DataView/Index';
import Landing from './pages/Landing/Index';
import withContext from './hoc/withContext';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-quill/dist/quill.snow.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import Alert from './components/Alert/Alert';
import LoadingOverlay from 'react-loading-overlay';
import { faSortUp, faSortDown, faPlus, faTimes, faPen, faEye, faEyeSlash, faMinus, faCalendar, faDownload, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './App.css';

import { LoginCallback, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { /*getOktaAuthObject*/ OktaAuthObject } from './helpers/adalConfig';

import apiUrls from './helpers/apiUrls';

library.add(faSortUp);
library.add(faSortDown);
library.add(faPlus);
library.add(faMinus);
library.add(faTimes);
library.add(faPen);
library.add(faEye);
library.add(faEyeSlash);
library.add(faCalendar);
library.add(faDownload);
library.add(faSyncAlt);
library.add(fab);




class App extends Component {


    componentDidMount = () => {
        this.props.history.listen((location, action) => {
            this.props.context.clearAlerts();
            this.props.context.hideLoading();

        });
    }

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
        //this.props.history.replace(toRelativeUrl("", window.location.origin));
    };

    render() {
        return (
            //<BrowserRouter>
            <React.Fragment>

                <link rel="stylesheet" type="text/css" href={`${apiUrls.blobStorage}/customer.css`} />

                <NavBar />
                <div className="content">
                    <LoadingOverlay spinner active={this.props.context.isLoading} styles={
                        {
                            wrapper: (base) => ({
                                ...base,
                                position: 'absolute',
                            }),
                            spinner: (base) => ({
                                ...base,
                                position: 'fixed',
                                top: '50%'
                            }),
                            overlay: (base) => ({
                                ...base,
                                position: 'fixed',
                            })
                        }
                    }>
                        {this.props.context.alerts.map((alert, index) => (
                            <Alert {...alert} key={index} onRemove={() => this.props.context.removeAlert(index)} />
                        ))}
                        <Switch>

                            {/*ugly hack because Okta has to be initialized OR not used at all*/}
                            {OktaAuthObject !== undefined ?
                                <Security oktaAuth={OktaAuthObject} context={this.props.context} restoreOriginalUri={this.restoreOriginalUri}>
                                    <ProtectedRoute exact path="/" component={Landing} />
                                    <ProtectedRoute path="/landingtile" component={Landing} />
                                    <Route path="/login/:returnUrl?" render={Login} />
                                    <ProtectedRoute path="/changepassword" render={Reset} />
                                    <ProtectedRoute path="/dataentry/" component={DataEntry} />
                                    <ProtectedRoute path="/dataview/" component={DataView} />
                                    <Route path={"/implicit/callback"} component={LoginCallback} />
                                </Security>
                                :
                                <React.Fragment>
                                    <ProtectedRoute exact path="/" component={Landing} />
                                    <ProtectedRoute path="/landingtile" component={Landing} />
                                    <Route path="/login/:returnUrl?" render={Login} />
                                    <ProtectedRoute path="/changepassword" render={Reset} />
                                    <ProtectedRoute path="/dataentry/" component={DataEntry} />
                                    <ProtectedRoute path="/dataview/" component={DataView} />
                                    <Route path={"/implicit/callback"} component={LoginCallback} />
                                </React.Fragment>
                            }

                        </Switch>
                    </LoadingOverlay>
                </div>

            </React.Fragment>
            //</BrowserRouter>
        );
    }
}

export default withContext(withRouter(App));
