const current_env = window.location.href;

let webentryAPI, userAPI, configAPI, dataAPI, blobStorage;

if (current_env.includes('localhost')) {
    // Redirection for Localhost to DEV
    webentryAPI = "https://dataentryapi.ibss.dev.iconics.cloud/api/webentry/v1/";
    userAPI = "https://userapi.ibss.dev.iconics.cloud/api/user/v1/Users/";
    configAPI = "https://serviceconfigapi.ibss.dev.iconics.cloud/api/config/v1";
    dataAPI = "https://dataapi.ibss.dev.iconics.cloud/api/data";
    blobStorage = "https://storage.ibss.dev.iconics.cloud/customer-style/customer"
}
else {
    let activeurl = window.location.href;
    let breakUrl = activeurl.split("/")
    let re = breakUrl[2];
    breakUrl = re.split(".")
    re = breakUrl[0];

    let host = window.location.hostname.replace(re, '');

    webentryAPI = `https://dataentryapi${host}/api/webentry/v1/`;
    userAPI = `https://userapi${host}/api/user/v1/Users/`;
    configAPI = `https://serviceconfigapi${host}/api/config/v1`;
    dataAPI = `https://dataapi${host}/api/data`;
    blobStorage = `https://storage${host}/customer-style/customer`;
}

const apiUrls =
{
    // urls
    webentryAPI,
    userAPI,
    configAPI,
    dataAPI,
    blobStorage
}

export default apiUrls;