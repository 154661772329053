import axios from 'axios';
import { AdalAuthObject, AdalConfig } from './adalConfig'
import authHeader from './authHeader';

import apiUrls from './apiUrls';
/**
 * Instance used for ajax calls in Config API.
 */

const instance = axios.create({
    baseURL: apiUrls.configAPI
});

instance.interceptors.request.use(config => {

    if (AdalAuthObject !== undefined) {
        // Check and acquire a token before the request is sent
        return new Promise((resolve, reject) => {
            AdalAuthObject.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
                if (!!token) {
                    config.headers.Authorization = `Bearer ${token}`

                    resolve(config)
                } else {
                    // Do something with error of acquiring the token
                    reject(config)
                }
            })
        })
    }

    config.headers.authorization = authHeader().Authorization;
    return config;
}, err => Promise.reject(err)
);

export default instance;